import React from 'react'
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import './testimonial.scss'
SwiperCore.use([Pagination]);

const Testimonial = ({testimonials}) => {
  const listTestimonials = testimonials.map((testimonial, index) =>
    <SwiperSlide key={index}>
      <div className="pb-16">
        <p className="lead text-white mb-6 w-full lg:w-3/4">
          {testimonial.text}
        </p>
        <h3 className="text-xl font-bold mb-2 text-copper-light">{testimonial.name}</h3>
      </div>
    </SwiperSlide>
  );
  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={50}
      pagination={{ clickable: true }}
    >
      {listTestimonials}
    </Swiper>
  )
}

export default Testimonial